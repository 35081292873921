import Head from "next/head";
import { useRouter } from "next/router";
import { useLocale } from "./lang/UseLocale";

const { t } = useLocale();
const blogTitle = "Magicode ads";
const blogDescription = t.SERVICEDESCRIPTION;
const facebookAppId = "975259896577593";
const blogAuthorTwitterUserName = "Magicode_io";

const OGPHead = ({ isRoot, title, description, imageUrl, cardType }: any) => {
  const router = useRouter();
  const type = isRoot ? "website" : "article";
  const blogImageUrl = process.env.baseUrl + (isRoot ? "home.jpg" : "bg.jpg");
  return (
    <Head>
      <title>{title || blogTitle}</title>
      <meta name="description" content={blogDescription} />
      <meta property="og:title" content={title || blogTitle} />
      <meta
        property="og:description"
        content={description || blogDescription}
      />
      <meta
        property="og:url"
        content={"https://jp.magicode.io" + router.asPath}
      />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={blogTitle} />
      <meta property="og:image" content={imageUrl || blogImageUrl} />
      <meta property="fb:app_id" content={facebookAppId} />

      <meta name="twitter:card" content={cardType || "summary_large_image"} />
      <meta name="twitter:site" content={`@${blogAuthorTwitterUserName}`} />
    </Head>
  );
};

export default OGPHead;
