import OGPHead from "@/components/OGP";
import { rfetchIndexPageData } from "@/src/api";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

const App: React.FC = () => {
  const router = useRouter();
  const [fetching, setFetching] = useState<boolean>(true);
  const [ads, setAds] = useState<any>();
  const [pv, setPv] = useState<any>();
  const [click, setClick] = useState<any>();
  const [count_dict, setCount_dict] = useState<any>();
  const [balance, setBalance] = useState<any>();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      rfetchIndexPageData()
        .then((r) => {
          console.log(r.data);
          !unmounted && setAds(r.data.ads);
          !unmounted && setPv(r.data.pv_count);
          !unmounted && setClick(r.data.click_count);
          !unmounted && setBalance(r.data.account_info.balance);
          !unmounted && setFetching(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return () => {
      unmounted = true;
    };
  }, []);
  return (
    <>
      <OGPHead title="ホーム" />
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          {fetching ? (
            <>
              <div className="text-center py-4">
                <CircularProgress color="secondary" />
              </div>
            </>
          ) : (
            <>
              {/* 予算 */}
              <div className="py-4">
                <div>
                  <b>残っている予算</b>
                </div>
                <div className="py-3">{balance}円</div>
                <p>予算の追加は<Link href="/payments/deposit"><a>こちら</a></Link>から</p>
              </div>
              {/* pvとクリック */}
              <div className="py-4">
                <div>
                  <b>PV & Click数（全期間）</b>
                </div>
                <div className="py-3">
                  <TableContainer component={Paper} className="light-shadow">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">PV</TableCell>
                          <TableCell align="left">Click</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{pv}</TableCell>
                          <TableCell align="left">{click}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              {/* 作成した広告一覧 */}
              <div className="py-4">
                <div>
                  <b>広告一覧</b>
                </div>
                <div className="py-3">
                  {ads?.length > 0 ? (
                    <>
                      <TableContainer
                        component={Paper}
                        className="light-shadow"
                      >
                        <Table aria-label="ads table">
                          <TableHead>
                            <TableRow>
                              <TableCell>タイトル</TableCell>
                              <TableCell align="left">審査状況</TableCell>
                              <TableCell align="left">掲載状況</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ads.map((ad, idx) => (
                              <TableRow
                                key={`ad${idx}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  router.push(`/ad/${ad.uuid}`);
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {ad.title}
                                </TableCell>

                                <TableCell align="left">
                                  {ad.judge_status == 1
                                    ? "審査中"
                                    : ad.judge_status == 2
                                    ? "通過"
                                    : "不合格"}
                                </TableCell>
                                <TableCell align="left">
                                  {ad.is_running ? "掲載中" : "未掲載"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <>
                      <Link href={"/ad/add"}>
                        <a>広告を作成する</a>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default App;
